* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f8fafc;
  color: #2c3e50;
  line-height: 1.8;
  font-size: 16px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar {
  background-color: #34495e;
  padding: 15px 30px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 25px;
}

.navbar li {
  margin: 0;
}

.navbar a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #1abc9c;
}

.navbar-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.button {
  background: linear-gradient(135deg, #1abc9c, #16a085);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 0.95rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #16a085, #1abc9c);
}

.App-header {
  background-color: #2c3e50;
  padding: 80px 20px;
  color: white;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.App-header h1 {
  font-size: 3.2rem;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.App-header p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  opacity: 0.85;
}

.search-bar {
  padding: 14px;
  width: 60%;
  max-width: 500px;
  border-radius: 25px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.3s ease;
  margin: 20px auto 0;
}

.search-bar:focus {
  box-shadow: 0 0 10px rgba(26, 188, 156, 0.5);
  border-color: #1abc9c;
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 40px 20px;
}

.content-box {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  width: 300px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.content-box h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

.content-box p {
  font-size: 0.95rem;
  color: #7f8c8d;
}

.footer {
  background-color: #34495e;
  color: white;
  padding: 40px 20px;
  text-align: center;
  margin-top: 50px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.logout-button {
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 0.95rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
}

.logout-button:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #c0392b, #e74c3c);
}

.logout-button:focus {
  outline: none;
}

.logout-button:active {
  transform: scale(0.98);
}

.footer p {
  margin: 10px 0;
  font-size: 1rem;
  opacity: 0.85;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.2);
  color: #1abc9c;
}

.welcome-message {
  font-size: 1.2rem;
  color: #fff;
  background-color: #1abc9c;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
